/* eslint-disable no-unused-vars */

export enum HTML_Form_Encoding_Type {
	default = 'application/x-www-form-urlencoded',
	multipart = 'multipart/form-data',
}

export enum Classical_100_Section {
	index = 'index',
	piece = 'piece',
	sorting = 'sorting',
	challenges = 'challenges',
	challenge = 'challenge',
	mood_changer = 'mood_changer',
	other = 'other',
}

// NOTE - the following are repeated from /app/src/types/enums.ts because we can't get CRA to load them from there

export enum Room_Layouts
{
	classroom = 'classroom',
	flexible = 'flexible',
}

export enum Content_Statuses
{
	draft = 'draft',
	published = 'published',
}

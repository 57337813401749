/**
 * Miscellaneous utility functions used throughout the frontend
 */
'use strict'

import Axios, { AxiosError, Method } from 'axios'
import React from 'react'
import { Content_Statuses, HTML_Form_Encoding_Type } from './types/enums'

/**
 * Creates a relative URL for the site, even if we're in dev mode and the two sites 
 * are under subpaths rather than on different domains
 * 
 * @param {string} path the relative path that we want to create the URL for
 * @param {'classroom200'|'classical100'} site which site to create the URL for. Creates an absolute URL if the specified site is not the current site
 * 
 * @returns {string} the URL
 */
export function url(path: string, site?: 'classroom200'|'classical100'): string
{
	// Make sure the path starts with a slash
	if (!path.startsWith('/'))
	{
		path = '/' + path
	}

	const current_site = window['current_site']
	if (!site)
	{
		site = current_site
	}

	// If we're in dev mode we want to use the subpaths
	if (process.env.REACT_APP_DEVMODE && !!+process.env.REACT_APP_DEVMODE)
	{
		if (!path.startsWith(`/${site}`))
		{
			path = '/' + site + path
		}
	}
	else
	{
		// Add domain if specified and it's not the current site
		if (site && site !== current_site)
		{
			if (site === 'classical100')
			{
				path = process.env.REACT_APP_CLASSICAL_100_URL + path
			}
			else 
			{
				path = process.env.REACT_APP_CLASSROOM_200_URL + path
			}
		}
	}

	// Remove trailing slash
	if (path !== '/')
	{
		path = path.replace(/\/$/, '')
	}

	return path
}

/**
 * Checks whether a path is an admin URL or not
 * 
 * @param {string} path the relative path that we want to check
 * 
 * @returns {boolean} true if path is an admin URL, false if not
 */
export function is_admin(path: string): boolean
{
	let admin_url = '/admin'

	// Make sure the path starts with a slash
	if (!path.startsWith('/'))
	{
		path = '/' + path
	}

	// If we're in dev mode we want to use the subpaths
	if (process.env.REACT_APP_DEVMODE && !!+process.env.REACT_APP_DEVMODE)
	{
		if (window['current_site'] === 'classroom200')
		{
			admin_url = '/classroom200' + admin_url
		}
		else if (window['current_site'] === 'classical100')
		{
			admin_url = '/classical100' + admin_url
		}
	}

	const matches = path.match(admin_url)
	if (!matches || matches.length === 0)
	{
		return false
	}

	return true
}

/**
 * Makes an api call to the backend via AJAX
 * 
 * @param {string} path the API path (e.g. '/login')
 * @param {Method} method the HTTP verb ('GET'|'PUT' etc) to use for the request
 * @param {FormData|Record<string, unknown>} data the data to send
 * @param {HTML_Form_Encoding_Type} content_type the type of encoding to use in the request
 * 
 * @returns {Promise<{data?: T, error?: boolean}>}
 */
export async function api<T = Record<string, unknown>>(path: string, method?: Method, data?: FormData|Record<string, unknown>, content_type?: HTML_Form_Encoding_Type): Promise<{data?: T, error?: Error_Response}>
{
	if (!method)
	{
		method = 'GET'
	}	

	// Set options for Axios
	// See https://axios-http.com/docs/req_config for options reference
	const options = 
	{
		data: data,
		method: method,
		headers: {
			'Accept': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': content_type ? content_type : 'application/json',
		},
		withCredentials: true,
		xsrfHeaderName: 'X-CSRF-Token',
	}

	if (method !== 'GET')
	{
		if ('csrf_token' in window)
		{
			options.headers['X-CSRF-Token'] = window['csrf_token']
		}
		else
		{
			const { error } = await api('/api/generate-csrf-token')
			if (!error)
			{
				const { data, error } = await api('/api/get-csrf-token')
				if (!error && data && 'crumb' in data)
				{
					window['csrf_token'] = data.crumb
					options.headers['X-CSRF-Token'] = data.crumb
				}
			}
		}
	}
	
	try 
	{
		const response = await Axios(path, options)

		if (response.data.error)
		{
			return {
				error: {
					message: response.data.message,
					fields: response.data.fields,
					code: response.data.code,
				}
			}
		}

		return {
			data: response.data
		}
	}
	catch (e) 
	{
		const err = e as AxiosError
		if (err.response) 
		{
			const response = err.response

			console.log(response.data)
			console.log(response.status)
		}
		else if (err.request) 
		{
			// The request was made but no response was received
			console.log(err.request)
		}
		else 
		{
			// Something happened in setting up the request that triggered an Error
			console.log('Error', err.message)
		}

		// TODO handle server errors
		return {}
	}
}

/**
 * Shortcut for doing simple CSS animations. Elements must make use of .hide and .show classes to transition. 
 * Hides everything at the end.
 * 
 * @param {{time?: number, selectors: string[], state?: 'show'|'hide'|'unshow'}[]} options an array of animation states
 * @param {number} end_time the time that the animation should end and when we should do cleanup
 * 
 * @returns {void}
 */
export function animation(options: {time?: number, selectors: string[], state?: 'show'|'hide'|'unshow'}[], end_time: number): void
{
	const elements: HTMLElement[] = []
	
	for (let index = 0; index < options.length; index++) 
	{
		const option = options[index]

		if (!option.time)
		{
			option.time = 0
		}

		if (option.selectors && option.selectors.length > 0)
		{
			for (let index = 0; index < option.selectors.length; index++) 
			{
				const element = document.querySelector<HTMLElement>(option.selectors[index])
				if (element)
				{
					setTimeout(function()
					{
						if (option.state && option.state === 'hide')
						{
							element.classList.add('hide')
						}
						else if (option.state && option.state === 'unshow')
						{
							element.classList.remove('show')
						}
						else
						{
							element.classList.add('show')
						}
					}, option.time)

					elements.push(element)
				}
			}
		}
	}
	
	setTimeout(function()
	{
		elements.forEach(element => 
		{
			element.classList.remove('show', 'hide')
		})
					
	}, end_time)
}

/**
 * Event listener for React
 * 
 * @param {string} event 
 * @param {EventListenerOrEventListenerObject} listener 
 * @param {boolean | AddEventListenerOptions | undefined} options 
 */
export function event_listener(event: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions | undefined): void
{
	React.useEffect(() => 
	{
		document.addEventListener(event, listener, options)

		// Clean up the event every time the component is re-rendered
		return function cleanup() 
		{
			document.removeEventListener(event, listener)
		}
	})
}

/**
 * Output the content statuses for an HTML select
 * 
 * @returns HTML_Select_Option[]
 */
export function content_statuses(): HTML_Select_Option[]
{
	return [
		{
			value: Content_Statuses.draft,
			label: 'Draft',
		},
		{
			value: Content_Statuses.published,
			label: 'Published',
		},
	]
}

/**
 * Calculate how many unlocked challenges there are based on how many challenges have been completed by a group
 * 
 * @param challenges_completed 
 * @returns 
 */
export function calculate_unlocked_challenges(challenges_completed: number): number
{
	let unlocked_challenges = Math.floor(challenges_completed / 10) * 10
	unlocked_challenges += 10

	return unlocked_challenges
}

/**
 * React hook wrapper for setInterval
 * Thanks to Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * 
 * @param callback 
 * @param delay 
 */
export function use_interval(callback: () => void, delay: number): void
{
	const savedCallback = React.useRef<() => void>()

	// Remember the latest callback.
	React.useEffect(() => 
	{
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	React.useEffect(() => 
	{
		function tick() 
		{
			if (savedCallback.current)
			{
				savedCallback.current()
			}
		}

		if (delay !== null) 
		{
			const id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

/**
 * Client (frontend) entry point
 * 
 * There are 2 sites - Classroom 200 and Classical 100 - that we want to run on the same system
 * So we need to find out what the URL is and show the correct site accordingly. In production
 * these will be different URLs (probably classical100.org and classroom200.org). If we're in local
 * development, we want a quick, baked-in way of viewing/navigating the 2 sites without bothering
 * with vhosts etc, so we hard code an extra component to the path, either `/classroom200` or
 * `classical100`, depending on which site you're viewing
 */

import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { api } from './utilities'

let classroom_200_url = ''
let classical_100_url = ''

let classroom_200_url_regex
let classical_100_url_regex

let admin_url_regex

// Get the current URL of the page
const url = window.location.href

// Use dummy URLs if we're in dev mode, otherwise, get them
// Note that we match a Regular expression so that we don't have to worry about local port or SSL
if (process.env.REACT_APP_DEVMODE && !!+process.env.REACT_APP_DEVMODE)
{
	classroom_200_url_regex = new RegExp('^http[s]*://localhost:\\d+/classroom200')
	classical_100_url_regex = new RegExp('^http[s]*://localhost:\\d+/classical100')

	classroom_200_url = `${window.location.protocol}//localhost:${window.location.port}/classroom200`
	classical_100_url = `${window.location.protocol}//localhost:${window.location.port}/classical100`

	admin_url_regex = new RegExp('^http[s]*://localhost:\\d+/(classroom200|classical100)/admin')
}
else
{
	// TODO show an error if one of these is not set

	if (process.env.REACT_APP_CLASSROOM_200_URL_REGEX)
	{
		classroom_200_url_regex = new RegExp(process.env.REACT_APP_CLASSROOM_200_URL_REGEX)
	}
	if (process.env.REACT_APP_CLASSICAL_100_URL_REGEX)
	{
		classical_100_url_regex = new RegExp(process.env.REACT_APP_CLASSICAL_100_URL_REGEX)
	}
	if (process.env.REACT_APP_CLASSROOM_200_URL)
	{
		classroom_200_url = process.env.REACT_APP_CLASSROOM_200_URL
	}
	if (process.env.REACT_APP_CLASSICAL_100_URL)
	{
		classical_100_url = process.env.REACT_APP_CLASSICAL_100_URL
	}

	if (process.env.REACT_APP_CLASSROOM_200_URL_REGEX && process.env.REACT_APP_CLASSICAL_100_URL_REGEX)
	{
		admin_url_regex = new RegExp(`${process.env.REACT_APP_CLASSROOM_200_URL_REGEX}/admin|${process.env.REACT_APP_CLASSICAL_100_URL_REGEX}/admin`)
	}
}

if (classroom_200_url_regex && url.match(classroom_200_url_regex))
{
	window['current_site'] = 'classroom200'
}
else if (classical_100_url_regex && url.match(classical_100_url_regex))
{
	window['current_site'] = 'classical100'
}

// Load the app
{
	(async () => 
	{
		let App

		const { data: maintenance_mode } = await api('/api/maintenance-mode')

		// Set the import location based on the URL
		if (url.match(admin_url_regex))
		{
			App = await import('./components/admin/AdminApp')
		}
		else if (classroom_200_url_regex && url.match(classroom_200_url_regex))
		{
			if (maintenance_mode && maintenance_mode.in_maintenance_mode)
			{
				window['maintenance_message'] = maintenance_mode.message

				App = await import('./components/MaintenanceModeApp')
			}
			else
			{
				App = await import('./components/classroom200/Classroom200App')
			}
		}
		else if (classical_100_url_regex && url.match(classical_100_url_regex))
		{
			if (maintenance_mode && maintenance_mode.in_maintenance_mode)
			{
				window['maintenance_message'] = maintenance_mode.message

				App = await import('./components/MaintenanceModeApp')
			}
			else
			{
				App = await import('./components/classical100/Classical100App')
			}
		}
		else
		{
			// Redirect to Classroom 200 by default
			window.location.href = classroom_200_url
		}

		// Main app render
		ReactDOM.render(
			<React.StrictMode>
				<App.default classroom_200_URL={classroom_200_url} classical_100_URL={classical_100_url} />
			</React.StrictMode>, 
			document.getElementById('root'))
	})()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
